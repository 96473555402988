












import {Component, Vue} from 'vue-property-decorator'
import {VApp} from 'vuetify/lib'
import {mapGetters, mapState} from 'vuex'
import '~/assets/custom-theme/index.css'
import '~/assets/themify-icons/css/themify-icons.css'
import config from '~/configs'
import AuthLayout from '~/layouts/AuthLayout.vue'
import DefaultLayout from '~/layouts/DefaultLayout.vue'
import SimpleLayout from '~/layouts/SimpleLayout.vue'

@Component({
    components: {
        VApp,
        DefaultLayout,
        SimpleLayout,
        AuthLayout
    },
    computed: {
        ...mapState('app', ['noFrame']),
        ...mapGetters('app', {
            screenHeight: 'screenHeight'
        }),
        isRouterLoaded: function () {
            return this.$route.name !== null
        }
    },
    metaInfo: {
        title: '',
        titleTemplate: '%s | Admin',
        link: [
            // adds config/icons into the html head tag
            ...config.icons.map((href) => ({rel: 'stylesheet', href}))
        ]
    }
})
export default class App extends Vue {

    //  DATA
    noFrame: boolean


    get currentLayout() {
        let layout = this.$route.meta.layout || 'default'
        if (layout === 'default' && this.noFrame)
            layout = 'simple'
        return layout + 'Layout'
    }

    get wrapperClasses() {
        return this.$route.meta.classes
    }

    mounted() {
        this.$store.commit('app/setScreenWidthHeight', {width: window.innerWidth, height: window.innerHeight})
        window.addEventListener('resize', () => {
            return this.$store.commit('app/setScreenWidthHeight', {
                width: window.innerWidth,
                height: window.innerHeight
            })
        })
    }

}
